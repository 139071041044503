import './Documents.scss'

import React, { useEffect, useState, Fragment } from 'react'
import Button from '../Button'
import TheFrame from '../../components/Frame'
import DocumentsList from '../../components/Document/DocumentsList'
// import Input from '../../components/Input'
import DocumentsPlaceholder from './DocumentsPlaceholder'
import Select from '../../components/Select'
// import Tabs from '../Tabs/Tabs'
import Empty from './DocumentsEmpty'
// import TabItem from '../Tabs/TabItem'
import TabDoc from '../Tabs/TabDoc'
import Search from '../../components/Search'

import { connect } from 'react-redux'
import { loadUserDocuments as loadUserDocumentsAction } from "../../store/actions/userDocuments"
import { useHistory, useLocation } from 'react-router-dom'
import { TYPE_SIGNED, TYPE_UNSIGNED } from './sign.types'



const Documents = function ({ documents, loadUserDocuments, loading, partner }) {
    const defaultType = TYPE_UNSIGNED
    const history = useHistory()
    const location = useLocation()
    const show = (new URLSearchParams(location.search)).get('show')
    let [type, setType] = useState([TYPE_SIGNED, TYPE_UNSIGNED].indexOf(show) > -1 ? show : defaultType)
    const newDocument = () => history.push('/case/new')
    const [query, setQuery] = useState('')
    const [filter, setFilter] = useState(null)
    const filters = [{ text: 'Все исполнители', value: 1 }]

    useEffect(() => {
        loadUserDocuments()
    }, [])

    if (loading) {
        return <DocumentsPlaceholder />
    } else if (!documents.length) {
        return <Empty />
    }

    const filteredDocuments = documents.filter(doc => {
        let signMatched
        let queryMatched
        let filterMatched

        if (type === TYPE_SIGNED) signMatched = doc.signed === true
        if (type === TYPE_UNSIGNED) signMatched = doc.signed === false
        if (!signMatched) return false

        queryMatched = query.length > 0 ? doc.name.indexOf(query) > -1 : true
        if (!queryMatched) return false

        return true
        // filterMatched =
    })

    const changeState = (state) => {
        history.push(`/profile?show=${state}`)
        setType(state)
    }

    return <div className="Documents">
        <div className="Documents_controls">
            <TabDoc active={type === TYPE_UNSIGNED} onClick={() => changeState(TYPE_UNSIGNED)}>На подписании</TabDoc>
            <TabDoc active={type === TYPE_SIGNED} onClick={() => changeState(TYPE_SIGNED)}>Подписаны</TabDoc>

            {/* <Tabs className="Documents_tabs">
            </Tabs> */}
            {/* <Button view="extra" onClick={newDocument} className="Documents_addDocument">
                Загрузить документ
            </Button> */}
        </div>
        <TheFrame className="Documents_frame">
            <form className="Documents_filters">
                {/* <Select mode="radio" options={filters}></Select> */}
                <Search value={query} onChange={setQuery}  placeholder="Поиск" />
            </form>

            {!filteredDocuments.length
                ? <div>Нет элементов для отображения</div>
                : <Fragment>
                    <DocumentsList documents={filteredDocuments}/>
                </Fragment>
            }
        </TheFrame>
    </div>
}


export default connect(
    state => ({
        documents: state.userDocuments.data,
        loading: state.userDocuments.loading,
        // partner: state.auth.profile.partner,
    }),
    dispatch => ({
        loadUserDocuments: () => dispatch(loadUserDocumentsAction()),
    }),
)(Documents)