import React, { useCallback, useState } from "react";
import styles from "./styles.module.css";
import arrow from '../../icons/arrow.svg'


const Accordeon = ({ title, content }) => {
  const [isOpen, setToggle] = useState(true);

  const toggleHandler = useCallback(() => {
    setToggle(!isOpen);
  }, [isOpen]);

  return (
    <div className={styles.accordeon}>
      <div className={styles.title} onClick={toggleHandler}>
        <div className={styles.iconsDefaultArrowsChe}>
          <img className={styles.vectorIcon} alt="" src={arrow}/>
        </div>
        {title}
      </div>
      <div className={`${styles.content} ${isOpen ? styles.content_hide : ""}`}>
        {content}
      </div>
    </div>
  );
};

export default Accordeon;
