import React from 'react'
import { useParams } from 'react-router-dom'
import TheFrame from '../../components/Frame'
import Link from '../../components/Link'
import Document from '../../components/Document/Document'
import './PageDetails.scss'
import {formatCertName} from "../../utils/format"
import { TYPE_SIGNED, TYPE_UNSIGNED } from '../../components/Document/sign.types'

export default function ({ document }) {
    const { id } = useParams()
    const { signed } = document
    const signType = signed ? TYPE_SIGNED : TYPE_UNSIGNED

    return <div className="Page PageDetails">
        <h1 className="Title Page_title">
            Оферта со СберМаркетом
        </h1>

        <div className="Page_explan">
            Чтобы подписать оферту, нужно подтвердить номер телефона и вашу личность или компанию.
            Это безопасно и необходимо для начала работы
            со СберМаркетом
        </div>
        
        <div className='Page_offer'>
        </div>

        {/* {formatCertName(document.filename)} */}
        
        <TheFrame>
            <Document document={document}/>
        </TheFrame>
    </div>
}
