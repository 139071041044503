import './TabPending.scss'

import React from 'react'
import Button from '../Button'

export default function ({ className = '', active = false, children = null, ...other }) {

    const classList = `${className} TabPending ${active ? 'button_view_extra' : 'button_view_extra'}`

    return <Button className={classList} {...other}>
        {children}
    </Button>
}
