import React, { useState, useEffect } from 'react'
import Input from '../Input'
import Button from '../Button/index'
import FormField from '../FormField/index'
import PhoneInput from '../InputPhone/index'
import Form from '../Form/index'
import { requestAuthCode, requestAuthToken, requestAuthCallCode } from '../../store/actions/index'
import { useDispatch } from 'react-redux'
import './SigninForm.scss'

export const LOGIN_STEP_PHONE = 1
export const LOGIN_STEP_PIN = 2
const MODE_SMS = 'sms'
const MODE_CALL = 'call'

function getVerificationConfig (mode, { phone }) {
    const SMS_CODE_LENGTH = 6
    const CALL_CODE_LENGTH = 4
    const details = {
        [MODE_CALL]: {
            delay: 60,
            length: CALL_CODE_LENGTH,
            text: `Введите последние ${CALL_CODE_LENGTH} цифры номера`,
        },
        [MODE_SMS]: {
            delay: 60,
            length: SMS_CODE_LENGTH,
            // text: `Для продолжения введите код отправленный на ${phone}`,
            text: <div>Для продолжения введите код отправленный на <br></br><b>{phone}</b></div>,

        } 
    }    
    return details[mode] || {}
}

export default function ({ className = '', submit, onSigned }) {
    const [mode, setMode] = useState(MODE_CALL)
    const [step, setStep] = useState(LOGIN_STEP_PHONE)
    const [phone, setPhone] = useState()
    const dispatch = useDispatch()
    const [error, setError] = useState(false)
   
    function ErrorMessage ({ error }) {
        if (!error) return null
        return <div className="Text Text__sm Text__error"  style={{ marginTop: 4 }}>
            {error}
        </div>
    }


    function CodeResend ({ resend, timeout }) {
        if (timeout) {
            return <div>Отправить смс через {timeout}</div>
        } else {
            return <div style={{cursor: 'pointer'}} onClick={resend}> 
                {/* <strong>Отправить смс еще раз</strong>  */}
            </div>
        }
    }

    function StepPhone ({ next }) {
        const [phone, setPhone] = useState(null)
        return <Form onSubmit={ () => next(phone) }>
            <div className="Signin_comments">
                Введите номер телефона, чтобы войти или&nbsp;зарегистрироваться
            </div>

            <FormField>
                <PhoneInput 
                    name="phone" 
                    value={phone} 
                    onChange={setPhone}
                    label={'  Номер телефона'} 
                    width="available"
                />
            </FormField>
            <Button type="submit" view="extra" disabled={!phone}>
                Продолжить
            </Button>

        </Form>
    }

    function StepPin ({ delay, resend, next, text, length = 20 }) {
        const [pin, setPin] = useState()
        const mask = Array.from(new Array(length), () => '1').join('')
        const submit = () => next(pin)
        // const ButtonLabel = step === LOGIN_STEP_PHONE ? 'Продолжить' : 'Переотправить'

        const [t, setT] = useState(delay)
        useEffect(() => {
            const dec = () => setTimeout(() => setT(t - 1), 1000)
            if (t <= 0) return
            dec()
        }, [t])

        const renew = () => {
            resend()
            setT(delay)
        }

        function onKeyUp () {
            if (pin.length < length) setError(false);
            else if (pin.length === length) return submit();
        }
        return <Form onSubmit={submit}>
            <FormField>  
                { text ? (
                    <div className="Signin_comments">
                        {text}
                    </div>
                ) : null }
                
                <Input
                    clear={true}
                    maxLength={length}
                    className={`input_pin ${error ? 'input_er' : ''}`}
                    mask={mask}
                    label="Введите код"
                    width="available"
                    type="text"
                    value={pin} 
                    onChange={setPin}
                    onKeyUp={onKeyUp}
                />
            </FormField>

            <ErrorMessage error={error} />
                 
            {/* <div className='timer'>
                <CodeResend 
                    timeout={t} 
                    resend={renew} 
                />
            </div>                     */}

            {/* <Button type="submit" view="extra" disabled={t > 0}>
                Продолжить
            </Button> */}

            <Button view="extra" disabled={t > 0} onClick={renew}>
                {t === 0 ? 'Переотправить' : `Новый код - через ${t} с.`}
            </Button>
        </Form>
    }


    // Здесь вся бизнес-логика
    const steps = {
        [LOGIN_STEP_PHONE]: () => ({
            view: StepPhone,
            props: {
                next: async (phoneValue) => {
                    setPhone(phoneValue)
                    dispatch(requestAuthCallCode(phoneValue))
                    setStep(LOGIN_STEP_PIN)  
                }
            }
        }),
        [LOGIN_STEP_PIN]: () => {
            const { length, text, delay } = getVerificationConfig(mode, { phone })
            return {
                view: StepPin,
                props: {
                    delay,
                    resend: async () => {
                        await dispatch(requestAuthCode(phone))
                        setMode(MODE_SMS)
                    },
                    length,
                    text,
                    next: async (pin) => {
                        try {
                            await dispatch(requestAuthToken(phone, pin))
                            submit()    
                        } catch (e) {
                            setError('Код не подходит, попробуйте еще раз')
                        }
                    }
                }
            }
        }
    }

    const { 
        view: StepView,
        props: stepProps,
    } = steps[step]()    

    return <div className={`Signin ${className}`}>
        <h1 className="Title Signin_title">
            Вход в личный кабинет
        </h1>
        {/* <ErrorMessage error={error} /> */}
        <StepView { ...stepProps } />
    </div>
}