import React from 'react'
import Button from '../Button'
// import Logo from '../../img/mailru_logo.svg'
import Logo from '../../img/sbermarket.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import Link from '../Link'
import authService from '../../services/auth'
import {formatPhone} from "../../utils/format"
import Icon from '../Icon/Icon'
import DoorIcon from '../../icons/door-out.svg'
import * as STATUSES from '../../store/profile.statuses'
import './header.scss'

export default function ({ className = '', logo = null,  }) {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const logout = authService.logout.bind(authService)

    return <header className={`${className} Header`}>
        <RouterLink to="/" className="Header_logo">
            <img
                src={Logo}
                alt="Sbermarket"/>
        </RouterLink>

        {auth.token
            ? <div className="Header_controls">
                {auth.profile
                    ? <div className="Header_user">
                        <RouterLink to="/profile" className="Header_userName Text Text__md">
                                {auth.profile.username && formatPhone(auth.profile.username)}
                        </RouterLink>
                        {auth.profile.status !== STATUSES.EMPTY
                            ? <div className="Header_userCompany Text Text__sm">
                                {auth.profile.legal_name}
                            </div>
                            : null
                        }
                    </div>
                    : null
                }
                <Button className="Header_signout_new"
                        short
                        // icon={<Icon icon={DoorIcon}/>}
                        onClick={logout}
                        title="Выход">Выход</Button>
            </div>
            : null
        }
    </header>
}