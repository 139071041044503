import React from 'react'
import './footer.scss'

export default function ({ className = '' }) {
    const year = (new Date()).getFullYear()
    return (
        <footer className={`${className} Footer`}>
            <div className="Footer_copyright">
                {/* © Legium.io {year}.  */}
                Сервис предоставляет Legium.io &mdash; зарегистрированный товарный знак ООО &#171;Легиум&#187;. Все права защищены
            </div>
        </footer>
    )
}