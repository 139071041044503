import React from 'react'
import Button from '../Button'
import './TabItem.scss'

export default function ({ className = '', active = false, children = null, ...other }) {

    const classList = `${className} TabItem ${active ? 'button__registration_active' : 'Button__registration'}`
    // const classList = `${className} TabItem ${'Button__registration'}`


    return <Button className={classList} {...other}>
        {children}
    </Button>
}