import React from 'react'
import PhoneInput from 'arui-feather/phone-input'

export default function ({
        className = '',
        size = 's',
        view = 'filled',
        ...rest
    }) {
    return <PhoneInput
        className="Input PhoneInput"
        view={view}
        size={size}
        {...rest} />
}