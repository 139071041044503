import React from 'react'
import Link from '../../components/Link'
import {connect} from 'react-redux'
import {billRequisites} from "../../store/other"
import TheFrame from '../../components/Frame'
import TYPES from '../../store/customer.types'
import ok from '../../icons/ok.svg'
import './Pending.scss'

import Button from '../../components/Button'
import TabPending from '../../components/Tabs/TabPending'


const ProfilePending = ({profile}) => {

    function download (url) {
        window.location.assign(url, '_self');
        // window.location = url
        // window.open(url)
    }

    const isOfficial = [
        TYPES.TYPE_ORGANIZATION,
        TYPES.TYPE_ENTERPRENEUR
    ].indexOf(profile.legal_form) > -1

    return (
        <div className="Page">
            <div className='Ok_logo'>
                <img src={ok} alt="Sbermarket"/>
            </div>
            <h1 className="Pending_Title">Поздравляем, вы стали партнёром СберМаркета!</h1>
            
            <TheFrame>
                <p className="Panding_Com">
                    Осталось сделать верификационный платёж с вашего расчётного счёта в банке по реквизитам ниже. 
                    В&nbsp;течение 1&nbsp;-&nbsp;2&nbsp;дней с вами свяжется наш менеджер и расскажет, 
                    как&nbsp;работать в личном кабинете.
                </p>


                {/* {isOfficial
                    ? <p className="Text Text__lg">
                        Не забудьте, что для идентификации необходимо перевести <span
                        style={{fontWeight: 'bold'}}>1₽</span>&nbsp;с&nbsp;вашего расчетного счета в&nbsp;банке
                        по&nbsp;этим реквизитам&nbsp;(
                        <Link target="_blank" color url={billRequisites.pdf}>pdf</Link>
                        )
                    </p>
                    : null
                } */}

                <div className="Documents_controls">
                    <TabPending onClick={() => download("https://api.legium.io/static/bill_new.pdf")} target="_blank">Счет на оплату PDF</TabPending>
                    <TabPending onClick={() => download("https://api.legium.io/static/bill.docx")} target="_blank">Счет на оплату DOCS</TabPending>
                </div>
            </TheFrame>

            <div className='Documents_comment_1'>
                Мы всегда на связи и готовы прийти на помощь!
                Команда подключения ответит на оставшиеся вопросы 
                про&nbsp;сотрудничество и&nbsp;оферту <font color="#148F2B">+7 931 009-69-86</font>
            </div>
            <div className='Documents_comment_2'>
                Команда запуска поможет с онбордингом и заполнением меню 
                в&nbsp;личном кабинете <font color="#148F2B">+7 495 150-44-47</font>
            </div>
        </div>
    )
}

export default connect(
    state => ({profile: state.auth.profile}),
    null,
)(ProfilePending)
