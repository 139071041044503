import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Button from '../Button'
import Link from '../Link'
import './Document.scss'
import FormField from '../FormField/index'
import {formatPartner} from '../../utils/formatPartner'
import {formatCertName} from "../../utils/format"
import {formatDate} from "../../utils/date"
// import DocumentSignForm from '../Forms/DocumentSignForm'
import SkillboxSignForm from "../Forms/SkillboxSignForm";
import Modal from '../Modal/Modal'
import Input from '../../components/Input'
import LinkIcon from '../../icons/link.svg'
import Icon from '../Icon/Icon'
import Illustration from '../../img/illustrations/document-pencil.svg'
import * as STATUSES from '../../store/profile.statuses'

import Accordeon from "../Accordeon";



const doc = document

const FAQ_DATA = [
    {
        title: "Что такое legium.io?",
        content:
            "Это российский сервис электронного документооборота с удалённой идентификацией. Он ускоряет и упрощает процесс подписания документов.",
    },
    {
        title: "Почему вы используете «Модульбанк»?",
        content:
            "Мы пользуемся «Модульбанком» из-за высокой скорости обработки поступающих платежей. Благодаря этому ваш контрагент быстрее увидит, что вы подписали документ.",
    },
    { 
        title: "На что влияет сумма платежа? Что будет, если заплатить 99 ₽, а не 1 ₽?",
        content:
            "Если вы заплатите 99 ₽, то банк быстрее переведёт платёж. Также вы получите дополнительное смс-уведомление об успешном подписании документа.",
    },
    {
        title: "Почему Legium.io отображается в клиент-банке как недобросовестный контрагент?",
        content:
            "Мы можем отображаться у некоторых банков как «контрагент, требующий внимания» из-за нашей специфической для российского рынка управленческой структуры. Однако бояться не нужно. В 2020 году ООО «Легиум» —получила инвестиции от Сбера. Это также может быть причиной низкой оценки со стороны вашего банка.",
    },
    {
        title: "Что делать, если мне не приходит код?",
        content:
            "Если код не пришёл, дождитесь окончания отсчёта и нажмите кнопку «Отправить код ещё раз». Если проблема не решится, обратитесь, пожалуйста, в службу поддержки.",
    },
    {
        title: "Что делать, если ссылка со счётом не работает?",
        content:
            "Напишите, пожалуйста, в службу поддержки, и мы вам поможем.",
    },
    {
        title: "Почему я оплачиваю  Legium.io через «Модульбанк», а выплачивает деньги мне «Инстамарт Сервис»? Для чего это нужно?",
        content:
            "Мы являемся партнёром «Инстамарт Сервис» и просто помогаем подписывать документы. Все последующие расчёты у вас будут происходить с «Инстамарт сервис» (компания СберМаркет).",
    },
    {
        title: "Будут ли закрывающие документы на 1 ₽ или эту сумму вернут?",
        content:
            "Мы вернём вам 1 ₽ и по необходимости пришлём закрывающий документ.",
    },
    {
        title: "Не могу зарегистрировать другое юридическое лицо по своему номеру",
        content:
            "На 1 номер можно зарегистрировать только 1 юридическое лицо. Если вы хотите поменять юридическое лицо, напишите нам в поддержку, мы поможем с этим вопросом.",
    },
    {
        title: "Что такое НЭП?",
        content:
            "Согласно Федеральному закону Российской Федерации от 6 апреля 2011 г. №63-ФЗ «Об электронной подписи», это информация в электронно-цифровой форме, которая используется для идентификации физического или юридического лица.",
    },
];

const Document = function ({ document, history, profile }) {
    const { meta, url, hashsum, is_signed_by_user, signatures } = document
    const { executor } = meta
    const [inited, setInited] = useState(false)
    const [copied, setCopied] = useState(false)
    const demo = true
    const hasClipboardFeature = !!navigator.clipboard

    useEffect(() => {
        doc.title = `Сертификат онлайн подписи | ${formatCertName(document.filename)}`
    }, [])

    useEffect(() => {
        if (profile && profile.status !== STATUSES.APPROVED) {
            history.push('/profile')
        }
    }, [profile])

    function onSigned () {
        setInited(false)
    }

    function print () {
        window.print()
    }

    async function copyLink () {
        await navigator.clipboard.writeText(window.location.href)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    return <div className="Document">
        <div className="Document_main">
            <ul className="Document_properties">

                <li className="Document_property">
                    <div className="Document_propertyLabel Text Text__mdx gray">
                        Оферта на заключения договора оказания услуг
                    </div>
                </li>
                
                <li className="Document_property">
                    <div className="Document_propertyInfoAndFile Text Text__mdx">
                        {/* {href = {url} */}
                        <Link href={url} color line={false} target="_blank" className="break-all">
                            {url}
                        </Link>
                    </div>
                </li>
                
                <div className='Document_signs'>
                    {signatures.length === 1 && (
                        <li className='Document_property' key={signatures[0].inn}>
                            <div className='Document_propertyValue Text Text__mdx'>
                                <li className="Document_property">
                                    <div className="Document_propertyLabel Text Text__mdx gray">
                                        СберМаркет
                                    </div>
                                </li>
                                <div>
                                    {signatures[0].inn ? (
                                        <div>
                                            {signatures[0].full_name} (ИНН: {signatures[0].inn}) в {formatDate(signatures[0].date)}{' '}
                                            {/* , отправленного на&nbsp;номер телефона {signatures[0].phone}{' '} */}
                                        </div>
                                    ) : (
                                        <div>
                                            Неидентифицированный пользователь в {formatDate(signatures[0].date)} при&nbsp;помощи секретного кода,
                                            отправленного на&nbsp;номер телефона {signatures[0].phone}{' '}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </li>
                    )}
                    {signatures.length ===2 && (
                    <li className='Document_property' key={signatures[0].inn}>
                        <div className='Document_propertyValue Text Text__mdx'>
                            <li className="Document_property">
                                <div className="Document_propertyLabel Text Text__mdx gray">
                                    СберМаркет
                                </div>
                            </li>
                            <div>
                                {signatures[0].inn ? (
                                    <div>
                                        {signatures[0].full_name} (ИНН: {signatures[0].inn}) в {formatDate(signatures[0].date)}{' '} при&nbsp;помощи
                                        секретного кода
                                        {/* , отправленного на&nbsp;номер телефона {signatures[0].phone}{' '} */}
                                    </div>
                                ) : (
                                    <div>
                                        Неидентифицированный пользователь в {formatDate(signatures[0].date)} при&nbsp;помощи секретного кода,
                                        отправленного на&nbsp;номер телефона {signatures[0].phone}{' '}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='Document_propertyValue Text Text__mdx'>
                            <li className="Document_property">
                                <div className="Document_propertyLabel Text Text__mdx gray">
                                    Партнёр
                                </div>
                            </li>
                            <div>
                                {signatures[1].inn ? (
                                    <div>
                                        {signatures[1].full_name} (ИНН: {signatures[1].inn}) в {formatDate(signatures[1].date)} при&nbsp;помощи
                                        секретного кода, отправленного на&nbsp;номер телефона {signatures[1].phone}{' '} </div>
                                    ) : (
                                    <div>
                                        Неподтверждённый пользователь ({signatures[1].phone}{' '})
                                        {/* Неидентифицированный пользователь в {formatDate(signatures[1].date)} при&nbsp;помощи секретного кода,
                                        отправленного на&nbsp;номер телефона {signatures[1].phone}{' '} */}
                                    </div>
                                )}
                            </div>
                        </div>
                    </li>
                    )}
                </div>
            </ul>

            {/* <div className='Document_identificationLabel'>
                Подтверждение личности или компании
            </div> */}

            {/* <div className='Document_identificationText'>
                Последний шаг для принятия оферты. Будьте готовы 
                сделать подтверждающий платёж 1 ₽. После этого вы 
                сможете подписывать все документы онлайн
            </div> */}

            <div className='Document_identificationLabel'>
                Подтверждение номера телефона
            </div>

            <div className="Document_controls">
                {!is_signed_by_user
                    ? !inited
                        ?
                        <div>
                            <div className="Signin_comments">
                                Позвоним на него или отправим смс, чтобы показать проверочный код
                            </div>
                            <Button
                                type="button"
                                onClick={() => setInited(true)}
                                view="extra"
                                width={'available'}
                                className={'Document_mainButton'}
                                noSizeChange={true}
                                >
                                Подписать
                            </Button>
                        </div>
                        :
                        <div>
                            <SkillboxSignForm onSigned={onSigned} demo={demo} id={document.public_id}/>
                        </div>
                    :
                    <div>
                        <div className="Signin_comments">
                            Последний шаг для принятия оферты. 
                            Будьте готовы сделать подтверждающий платёж 1 ₽. 
                            После этого вы сможете подписывать все документы онлайн
                        </div>
                        <Button
                            type="button"
                            onClick={print}
                            view="action"
                            width={'available'}
                            className={'Document_mainButton'}
                            noSizeChange={true}
                        >
                            Распечатать
                        </Button>
                    </div>
                }
            </div>
            
            <div className='question'>
                Вопросы и ответы
            </div>
            <div className="faq">
                {FAQ_DATA.map(({ title, content }, index) => (
                    <Accordeon key={index} title={title} content={content} />
                ))}
            </div>
        </div>
        
        {/*<Modal isOpen={inited} onRequestClose={() => setInited(false)}>*/}
        {/*    <DocumentSignForm onSigned={onSigned} demo={demo} id={document.public_id}/>*/}
        {/*</Modal>*/}
    </div>
}

export default connect(
    state => ({profile: state.auth.profile}),
    null
) (withRouter(Document))
