import React, {useState, useEffect} from 'react'
import Input from '../Input'
import Button from '../Button/index'
import Form from '../Form/index'
import FormField from '../FormField/index'
import PhoneInput from '../InputPhone/index'
import { useDispatch, useSelector } from 'react-redux'
import { commitLoginSignature } from "../../store/actions/signature"
// import {requestAuthCode} from "../../store/actions/auth"
import { requestAuthCode, requestAuthToken, requestAuthCallCode } from '../../store/actions/index'
import './DocumentSignForm.scss'

export const LOGIN_STEP_PHONE = 1
export const LOGIN_STEP_PIN = 2
const MODE_SMS = 'sms'
const MODE_CALL = 'call'

function getVerificationConfig (mode, { phone }) {
    const SMS_CODE_LENGTH = 6
    const CALL_CODE_LENGTH = 4
    const details = {
        [MODE_CALL]: {
            delay: 60,
            length: CALL_CODE_LENGTH,
            text: `Введите последние ${CALL_CODE_LENGTH} цифры номера`,
        },
        [MODE_SMS]: {
            delay: 60,
            length: SMS_CODE_LENGTH,
            // text: `Для продолжения введите код отправленный на ${phone}`,
            text: <div>Для продолжения введите код отправленный на <br></br><b>{phone}</b></div>,

        } 
    }    
    return details[mode] || {}
}

export default function ({ id, demo = false, className = '', submit, onSigned }) {
    const currentPhone = useSelector((state) => state.auth.profile && state.auth.profile.username)
    const [phone, setPhone] = useState(currentPhone)
    const [mode, setMode] = useState(MODE_CALL)
    const [step, setStep] = useState(LOGIN_STEP_PHONE)
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
   
    function ErrorMessage ({ error }) {
        if (!error) return null
        return <div className="Text Text__sm Text__error"  style={{ marginTop: 4 }}>
            {error}
        </div>
    }

    function CodeResend ({ resend, timeout }) {
        if (timeout) {
            return <div>Отправить смс через {timeout}</div>
        } else {
            return <div style={{cursor: 'pointer'}} onClick={resend}> 
                {/* <strong>Отправить смс еще раз</strong>  */}
            </div>
        }
    }

    function StepPhone ({ next }) {
        const [phone, setPhone] = useState(null)
        return <Form onSubmit={ () => next(phone) }>
            {/* <div className="Signin_comments">
                Введите номер телефона, чтобы войти или&nbsp;зарегистрироваться
            </div> */}

            <FormField>
                <PhoneInput 
                    name="phone" 
                    value={phone} 
                    onChange={setPhone}
                    label={'  Номер телефона'} 
                    width="available"
                />
            </FormField>
            <Button type="submit" view="extra" disabled={!phone}>
                Продолжить
            </Button>

        </Form>
    }

    function StepPin ({ delay, resend, next, text, length = 20 }) {
        const [pin, setPin] = useState()
        const mask = Array.from(new Array(length), () => '1').join('')
        const submit = () => next(pin)
        // const ButtonLabel = step === LOGIN_STEP_PHONE ? 'Продолжить' : 'Переотправить'

        const [t, setT] = useState(delay)
        useEffect(() => {
            const dec = () => setTimeout(() => setT(t - 1), 1000)
            if (t <= 0) return
            dec()
        }, [t])

        const renew = () => {
            resend()
            setT(delay)
        }

        function onKeyUp () {
            if (pin.length < length) setError(false);
            else if (pin.length === length) return submit();
        }
        
        async function sign (e) {
            e && e.preventDefault()
            try {
                await dispatch(commitLoginSignature(
                    id,
                    phone,
                    pin,
                    demo
                ))
                onSigned()
            } catch(err) {
                setError('Код не подходит, попробуйте еще раз')
                // onSigned()
            }
        }

        return <Form onSubmit={submit}>
            <FormField>  
                { text ? (
                    <div className="textSign">
                        {text}
                    </div>
                ) : null }
                
                <Input
                    clear={true}
                    maxLength={length}
                    className={`input_pin ${error ? 'input_er' : ''}`}
                    mask={mask}
                    label="Введите код"
                    width="available"
                    type="text"
                    value={pin} 
                    onChange={setPin}
                    onKeyUp={onKeyUp}
                />
            </FormField>

            <ErrorMessage error={error} />
                 
            {/* <div className='timer'>
                <CodeResend 
                    timeout={t} 
                    resend={renew} 
                />
            </div>                     */}

            {/* <Button type="submit" view="extra" disabled={t > 0}>
                Продолжить
            </Button> */}

            <Button view="extra" disabled={t > 0} onClick={renew}>
                {t === 0 ? 'Переотправить' : `Новый код - через ${t} с.`}
            </Button>
        </Form>
    }


    // Здесь вся бизнес-логика
    const steps = {
        [LOGIN_STEP_PHONE]: () => ({
            view: StepPhone,
            props: {
                next: async (phoneValue) => {
                    setPhone(phoneValue)
                    dispatch(requestAuthCallCode(phoneValue))
                    setStep(LOGIN_STEP_PIN)  
                }
            }
        }),
        [LOGIN_STEP_PIN]: () => {
            const { length, text, delay } = getVerificationConfig(mode, { phone })
            return {
                view: StepPin,
                props: {
                    delay,
                    resend: async () => {
                        await dispatch(requestAuthCode(phone))
                        setMode(MODE_SMS)
                    },
                    length,
                    text,
                    next: async (pin) => {
                        try {
                            // await dispatch(requestAuthCode(phone))
                            // await dispatch(sign(phone, pin))
                            await dispatch(commitLoginSignature(
                                id,
                                phone,
                                pin,
                                demo
                            ))
                            submit()    
                        } catch (e) {
                            setError('Код не подходит, попробуйте еще раз')
                        }
                    }
                }
            }
        }
    }

    const { 
        view: StepView,
        props: stepProps,
    } = steps[step]()    

    return <div className={`signature ${className}`}>
        <div className="signatureComments">
            Позвоним на него или отправим смс, чтобы показать проверочный код
        </div>
        {/* <h1 className="Title Signin_title">
            Позвоним на него или отправим смс, чтобы показать проверочный код
        </h1> */}
        {/* <ErrorMessage error={error} /> */}
        <StepView { ...stepProps } />
    </div>
}

// export default function ({ id, demo = false, onSigned }) {
//     const [code, setCode] = useState()
//     const currentPhone = useSelector((state) => state.auth.profile && state.auth.profile.username)
//     const [phone, setPhone] = useState(currentPhone)
//     const dispatch = useDispatch()
//     const [error, setError] = useState(false)
//     const [initialized, setInitialized] = useState(!!phone)

//     useEffect(() => {
//         if (phone) requestCode()
//     }, [])

//     async function sign (e) {
//         e && e.preventDefault()
//         try {
//             await dispatch(commitLoginSignature(
//                 id,
//                 phone,
//                 code,
//                 demo
//             ))
//             onSigned()
//         } catch(err) {
//             setError('Код не подходит, попробуйте еще раз')
//             // onSigned()
//         }
//     }

//     function requestCode (e) {
//         if (e) e.preventDefault()
//         try {
//             dispatch(requestAuthCode(phone))
//             setInitialized(true)
//         } catch (err) {
//             setError('Ошибка')
//         }
//     }

//     function onKeyUpInput () {
//         if (code.length < 6) setError(false);
//         else if (code.length === 6) return sign();
//     }

//     function onFocusInput () {
//         setError(false);
//     }

//     return <div className="DocumentSignForm">
//         {!initialized
//             ? 
//             <form className="DocumentSignForm_inner" onSubmit={requestCode}>
//                 <div className="Signin_comments">
//                     Позвоним на него или отправим смс, чтобы показать проверочный код
//                 </div>
//                 <PhoneInput label={'  Номер телефона'} width="available" value={phone} onChange={setPhone}/>
//                 <Button type="submit" view="extra">Получить код</Button>
//             </form>
//             : 
//             <form className="DocumentSignForm_inner" onSubmit={sign}>
//                 <div className="Signin_comments">
//                     Для продолжения введите код отправленный на <br></br>
//                     <strong>{phone}</strong>
//                 </div>
//                 <Input
//                     clear={true}
//                     maxLength = {6}
//                     className={`input_pin ${error ? 'input_er' : ''}`}
//                     // rightAddons={''}
//                     mask='111111'
//                     label={'  Введите код из SMS'}
//                     width="available"
//                     type="text"
//                     onKeyUp={onKeyUpInput}
//                     onFocus={onFocusInput}
//                     value={code} onChange={setCode}
//                 />
//                 {error
//                     ? <div className="Text Text__sm Text__error" style={{ marginTop: 4}}>{error}</div>
//                     : null
//                 }    
//                 <Button type="submit" view="extra">Подтвердить</Button>
//             </form>
//         }
//     </div>
    
// }