import './TabItem.scss'
import React from 'react'
import Button from '../Button'

export default function ({ className = '', active = false, children = null, ...other }) {

    const classList = `${className} TabDoc ${active ? 'button__docs_active' : 'button__docs'}`

    return <Button className={classList} {...other}>
        {children}
    </Button>
}
