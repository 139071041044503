import './styles/index.scss'
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route as BaseRoute,
    Redirect,
    Link as RouterLink
} from 'react-router-dom'
import Route from './components/Route/Route'
import Layout from './components/Layout'
import PrivateRoute from './components/Route/Private'
import Signin from './pages/Signin/index'
import Documents from './pages/Document/Documents'
import NewCase from './pages/Document/Add'
import NoMatch from './pages/Error/NoMatch'
import Document from './pages/Document/Document'
import Profile from './pages/Profile/Profile'
import { useDispatch } from 'react-redux'
import { loadProfile } from "./store/actions/auth"

function App() {
    const dispatch = useDispatch()

    dispatch(loadProfile())

    return (
        <Router>
            <Layout>
                <Switch>
                    <Redirect exact from="/" to="/profile"></Redirect>
                    <Route exact path="/login"><Signin/></Route>
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/case"  component={Documents} />
                    <PrivateRoute exact path="/case/new" component={NewCase} />
                    <Route exact path="/case/:id" component={Document} />
                    <Route path="*" component={NoMatch}></Route>
                </Switch>
            </Layout>
        </Router>
    );
}

export default App;
