import React, {useEffect} from 'react'
import {Route} from 'react-router-dom'

const PAGE_TITLE_DEFAULT = 'Подписание документов Legium.io для SberMarket'

export default function ({ title, ...rest }) {

    useEffect(() => {
        document.title = title || PAGE_TITLE_DEFAULT
    }, [title])

    return <Route {...rest} />
}